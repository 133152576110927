exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-docs-create-element-tsx": () => import("./../../../src/pages/docs/createElement.tsx" /* webpackChunkName: "component---src-pages-docs-create-element-tsx" */),
  "component---src-pages-docs-getstarted-tsx": () => import("./../../../src/pages/docs/getstarted.tsx" /* webpackChunkName: "component---src-pages-docs-getstarted-tsx" */),
  "component---src-pages-docs-react-email-tsx": () => import("./../../../src/pages/docs/reactEmail.tsx" /* webpackChunkName: "component---src-pages-docs-react-email-tsx" */),
  "component---src-pages-docs-tsx": () => import("./../../../src/pages/docs.tsx" /* webpackChunkName: "component---src-pages-docs-tsx" */),
  "component---src-pages-impact-tsx": () => import("./../../../src/pages/impact.tsx" /* webpackChunkName: "component---src-pages-impact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-playground-index-tsx": () => import("./../../../src/pages/playground/index.tsx" /* webpackChunkName: "component---src-pages-playground-index-tsx" */),
  "component---src-pages-report-tsx": () => import("./../../../src/pages/report.tsx" /* webpackChunkName: "component---src-pages-report-tsx" */)
}

